<template>
  <div class="item item-coop">
    <router-link :to="prjUrl" class="poster">
      <img :src="state.image || state.coverImage" />
    </router-link>

    <div class="content">
      <div class="info">
        
        <div class="title">
          <div class="inner">
            <div class="avator">
              <!-- <svg-icon icon="icon_avator"></svg-icon> -->
              <router-link :to="prjUrl">
              <span>{{ state.userNickname }}</span>
              </router-link>
            </div>
            <div></div>
          </div>
          <div style="font-size: 14px;font-weight: normal;">职业：{{state.userIndustry}}  <span style="margin-left:15px">头衔：</span>{{  state.userTitle }} </div>
          <div class="meta">
            <div class="meta-title">
              <el-rate v-model="rate" disabled></el-rate>
              <span style="margin-left: 5px"
                >{{ state.province }}{{ state.city }} {{ state.area }}
              </span>
            </div>
            
          </div>
        </div>
        <div class="desc" v-if="state.content">
          {{ state.content }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, reactive, computed } from 'vue'

const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
})
const rate = ref(0)
const prjUrl = computed(() => {
  return '/member/' + state.id
})
const state = reactive({ ...(props.data || {}) })
rate.value = Number(state.userStarLevel) || 0
</script>
<style lang="scss" scoped>
.item {
  padding: 5px;
  display: flex;
  box-sizing: border-box;
  .poster {
    display: block;
    width: 110px;
    height: 110px;
    flex-basis: 110px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info {
    text-align: left;
    padding-left: 15px;
    flex: 1;
    .title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .desc {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* 显示的行数 */
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5em; /* 每行的高度 */
      max-height: 4.5em; /* line-height * 行数 */
      color: #ababab;
    }
  }
}
.tags {
  text-align: left;
  font-size: 14px;
  margin-top: 3px;
  .tag {
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #ececec;
    border-radius: 3px;
    padding: 1px 5px;
    background-color: white;
    cursor: pointer;
    &:hover {
      color: rgb(22, 130, 213);
    }
  }
}
</style>
